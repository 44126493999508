<template>
  <v-app>
    <v-app>
    <v-banner class="lavBanner" single-line  color="#F5F8FD">
      <v-img
        src="https://h5.ophyer.cn/official_website/banner/developH5_banner1.png"
        height="100%"
        min-height="250"
        class="d-flex align-center"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="pa-0">
          <v-card color="transparent" flat class="banner-box">
            <v-card-title class="banner-title white--text font-size-h5banner-title  text-shadow-item font-weight-bold mt-1">
              创造属于你的<br/>领地
            </v-card-title>
            <!-- <v-btn
             width="110"
             class="banner-btns font-size-14 mt-6"
             color="rgba(25, 22, 38, 0.2)"
             style="border-radius: 5px;border: 1px solid;"
             @click="currentHovershow = true"
          >
              下载编辑器
          </v-btn> -->
          </v-card>
        </v-container>
      </v-img>
    </v-banner>
    <!-- 如何创造自己的领地 -->
    <v-card class="warps warp1 bgimg" color="#191B1D" >
      <div class="metaverse-title">
        <p>如何创造自己的领地</p>
      </div>
      <div class="mx-3 mb-7">
         <v-img min-height="417" src='https://h5.ophyer.cn/official_website/other/developH51-1-1.png' contain>
          <template v-slot:placeholder><img-placeholder></img-placeholder></template>
         </v-img>
      </div>
    </v-card>
    <v-card class="warps warpcrate" >
     <div class="warpcrate-title">
        <div >创造领地离不开强大的编辑器</div>
      </div>
    </v-card>
    <!-- VR全景编辑器功能介绍 -->
    <v-card class="warps warp2 py-6 position-r">
      <div class="Home-title">
        <p class="font-size-18">VR全景编辑器功能介绍</p>
      </div>
      <v-tabs v-model="advantageTab" background-color="transparent" class="xs-tab " color="white" dark>
        <v-tab v-for="(v, k) in list" :key="k" class="pa-0"
               :class="[k === 0 ? 'ml-5':'ml-6',k === list.length - 1 ? 'mr-5':'']"
               style="min-width: unset;color:rgba(255, 255, 255, 0.7)" @click="clickItem(v.id)">
          <div class="font-size-15 letter-spacing mr-6">{{ v.title }}</div>
          <span v-if='k!=4'>
            <div style="width: 1px;height: 14px;background: rgba(255, 255, 255, 0.7);border-radius: 1px;"></div>
          </span>
        </v-tab>
      </v-tabs>
      <v-tabs-items class="mt-3 mx-5" ref="tabItems" v-model="advantageTab" style="border-radius: 5px;box-shadow: 0px 0px 18px 0px rgba(0, 92, 193, 0.16);">
        <v-tab-item v-for="(v, k) in list" :key="k" :transition="false" class="xs-tab">
          <v-card class="px-5 pt-5 VRcard">
            <div>
              <img :src="v.img" :style="{'width':canvasWidth+'px','height':canvasHeight+'px','objectFit':'content'}" :ref="'image'+v.id" v-if="k==0"/>
              <img :src="v.img" :style="{'width':canvasWidth+'px','height':ImgHeight+'px','objectFit':'content'}" :ref="'image'+v.id" v-if="k!=0"/>
              <canvas v-show="v.id == 4" :class="'aroundImgPreview'+v.id" @mousedown="handleCanvasDown" @mousemove="handleCanvasMove" @mouseup="handleCanvasUp"></canvas>
            </div>
            <v-card-title class="pa-0 mt-5 font-size-18 font-weight-bold white--text" >{{v.h2}}</v-card-title>
            <v-card-text class="pa-0 mt-2 font-size-13 white--text" v-text="v.sub" v-html="v.sub" style="font-weight:400px"/>
            <v-card-text class="px-0 pt-4" style="line-height:26px;font-weight: 300;color: rgba(255, 255, 255, 0.7);" v-text="v.content" v-html="v.content" />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <v-row align="center" justify="center" style="width:30%" class="mt-4">
          <v-col v-for='(n,k) in list.length' :key='k' @click="gotab(k)" :cols="2.5" class="pa-0">
             <div :class="k==advantageTab?'lineclass--active':'lineclass'"></div>
          </v-col>
      </v-row>
      <div class="lineborder"></div>
    <!-- </v-card> -->

      <!-- VR全景编辑器功能介绍 -->
    <!-- <v-card class="warps warp2"> -->
      <div class="Home-title">
        <p class="font-size-18">VR全景编辑器使用场景</p>
      </div>
      <!-- VR全景编辑器使用场景 -->
      <v-container  style="" class="mb-10">
        <div class="carousel-container">
          <swiper ref="mySwiper" :options="swiperOption">
             <swiper-slide v-for="(item,index) in VRList" :key="index">
                <div style="position: relative;border-radius:5px">
                  <img :src="item.img" />
                  <div class="coverleft" style="position: absolute;top:0">
                     <img :src='item.icon' :style="{'width':item.width}" class="mt-7" />
                     <h2 class="font-size-16">{{item.h2}}</h2>
                     <div class="text-ends">
                      <h3 class="font-size-14">{{item.sub}}</h3>
                      <p class="font-size-10">{{item.p}}</p>
                     </div>
                 </div>
                </div>
             </swiper-slide>
          </swiper>
           <div class="swiper-pagination" slot="pagination" align="center" justify="center" style="width:100%;margin-top:22px"></div>
        </div>
      </v-container>
    </v-card>

    <!-- 3D编辑器产品介绍 -->
    <v-card class="warps warp3">
      <!-- <div class="metaverse-title">
        <p class="font-size-18">3D编辑器产品介绍</p>
      </div>
      <v-row class="justify-center px-8">
          <v-col cols="12" v-for="(item,index) in product" :key="index" class="d-flex child-flex mb-4">
                <v-card elevation="0">
                    <div>
                      <div class="d-flex justify-center">
                        <v-img :src="item.icon" :width="item.width" class="flex-grow-0" contain>
                          <template v-slot:placeholder>
                                  <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular
                                      indeterminate
                                      size="80"
                                      color="red lighten-5"
                                    ></v-progress-circular>
                                  </v-row>
                          </template>
                        </v-img>
                      </div>
                      <v-card-title class="white--text font-size-18 justify-center py-3" v-text="item.title"></v-card-title>
                      <div class="d-flex justify-center">
                        <v-card-text class="font-size-13 text-align-center justify-center py-0" style="color: rgba(255, 255, 255, 0.7);font-weight: 400;" v-text="item.content"></v-card-text>
                      </div>
                    </div>
                </v-card>
          </v-col>
      </v-row> -->
      <div class="lineborder"></div>
      <!-- 3D编辑器使用场景 -->
      <div class="metaverse-title">
        <p class="font-size-24">3D编辑器使用场景</p>
      </div>
      <v-container>
        <div class="carousel-container">
           <swiper ref="mySwiper" :options="swiperOptionTwo">
             <swiper-slide v-for="(item,index) in Dlist" :key="index" style="width:341px">
               <div class="gitStyle">
                 <div class="itemGif">
                   <img :src="item.img" class="imgone" style="" contain>
                   <img v-if="index == 1" class="imgTwo" src="https://h5.ophyer.cn/official_website/other/WanqingClou-5-lizi.gif" />
                 </div>
                 <div class="coverleft">
                     <img :src='item.icon' class="mt-7" :style="{'width':item.width}"/>
                     <h2 class="font-size-16">{{item.h2}}</h2>
                     <h3 class="font-size-14">{{item.sub}}</h3>
                     <p class="font-size-10">{{item.p}}</p>
                 </div> 
              </div>  
             </swiper-slide>
          </swiper>
           <div class="swiper-pagination_two" slot="pagination" align="center" justify="center" style="width:100%;margin-top:16px;margin-bottom: 24px;"></div>
        </div>
      </v-container>
    </v-card>
    <!-- 使用教程 -->
    <v-card class="warps warp4" color="#191B1D" >
      <div class="metaverse-title">
        <p>使用教程</p>
      </div>
      <v-row class="pa-0 px-2" style="margin:0 auto;margin-bottom:12px;width:100%;">
          <v-col v-for="(info,infoIndex) in data" :key="infoIndex" :cols='6' class="d-flex justify-center">
             <v-card flat class="h5-card item-card" width="100%" @click="goCaseDetail(info.id)" style="border-radius:5px;">
                <v-img max-width="100%" contain :src="info.coverImg"></v-img>
                <div class="text-box px-3">
                    <v-card-text class="sizeStyle pa-0 mt-3 mb-1 title" v-text="info.itemTitle"></v-card-text>
                    <v-card-text class="sizeStyle pa-0 tip pb-7" v-text="info.itemTip"></v-card-text>
                </div>
              </v-card>
          </v-col>
         
      </v-row>
       <p class="font-size-14 white--text more" style="text-align: center;" @click="gotoExperience">
             更多教程<img src="../../../../assets/images/home/develop-icon.png" style="width:30px;margin-top:5px;" class="pl-2">
        </p>
    </v-card>
     <!-- 客服热线 -->
     <div class="dialog" v-show="currentHovershow" @click.stop="currentHovershow = false">
      <transition name="fade" mode="out-in" @click.stop>
        <div class="content-tryout" @click.stop>
          <v-card color="transparent" flat @click.stop>
            <v-card-title class="justify-end pb-0 close-btn" @click.stop="currentHovershow = false">
              <img width="11px" height="12px" src="https://h5.ophyer.cn/official_website/other/metaverse-diaog-clone.png" alt="">
            </v-card-title>
            <img style="min-height: 180px;width: 100%;" src="https://h5.ophyer.cn/official_website/other/metaverse-diaog-bg.png" alt="">
            <div class="box">
              <img src="https://h5.ophyer.cn/official_website/other/metaverse-diaog-icon.png" alt="">
              <div>
                <p>010-63037996</p>
                <p>客服热线</p>
              </div>
            </div>
          </v-card>
        </div>
      </transition>
    </div>
  </v-app>
  </v-app>
</template>

<script>
let _this;
import contentData from '@/static/contentData.js';
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import FramePlayer from 'frame-player';
import { mapState } from "vuex";
export default {
  name: 'Home',
  components: {
     Swiper,
     SwiperSlide,
  },
  data() {
    return {
      value:0,
      screenHeight: window.innerHeight,
      tab:null,
      timer:null,
      advantageTab:null,
      data: [contentData.developCase[0].tabItem[0],contentData.developCase[0].tabItem[1]],
      list:contentData.DevelopHome.list,
      product:contentData.DevelopHome.product,
      VRList:contentData.DevelopHome.VRList,
      Dlist:contentData.DevelopHome.Dlist,
      currentHovershow:false,
      currentHover: 1,
      imgIndex: 2,
      swiperOption: {
        slidesPerView: "auto",
        centeredSlides:true,
        spaceBetween: -10,
        loop: true,
        autoplay: 3000,
        // pagination: {
        //  el: '.swiper-pagination',
        //  clickable:true
        // },
        pagination : '.swiper-pagination',
        paginationClickable :true,
      },
      swiperOptionTwo:{
        slidesPerView: "auto",
        centeredSlides:true,
        spaceBetween: -10,
        loop: true,
        autoplay: 3000,
        // pagination: {
        //   el: '.swiper-pagination_two',
        //   clickable:true
        // },
        pagination : '.swiper-pagination_two',
        paginationClickable :true,
      },
      framePlayer: null, //360物体预览对象
      around_play: false, //360物体播放控制
      around_scale: 100,
      sanliuleng: null,
      imgArr:contentData.DevelopHome.imgArr,
      canvasWidth:100,
      canvasHeight:100,
      ImgHeight:100,
      ImgWidth:100
    }
  },
  created() {
    this.newFramePlayer();
  },
  computed: {
    ...mapState(["officialWebsite"]),
    cardCols(){
      let colsValue = 6
      if(_this.$vuetify.breakpoint.name == 'xs' || _this.$vuetify.breakpoint.name == 'sm'){
        colsValue = 6
      } else if(_this.$vuetify.breakpoint.name == 'md'){
        colsValue = 4
      }else{
        colsValue = 3
      }
      return colsValue
    }
  },
  mounted() {
    _this = this;
    clearInterval(_this.timer)
    _this.setTimer()
    // window.onresize = this.canvasWh;
    this.canvasWidth = document.body.clientWidth - 82;
    this.canvasHeight = this.canvasWidth/1.734;
    this.ImgHeight=this.canvasWidth/1.735
    //this.ImgHeight=this.canvasWidth/1.733
  },
  watch: {
    "officialWebsite.windowResize": function (){
      this.canvasWh();
    }
  },
  methods: {
    canvasWh(){
      this.canvasWidth = document.body.clientWidth - 82;
      this.canvasHeight = this.canvasWidth/1.734;
      this.ImgHeight=this.canvasWidth/1.733
      setTimeout(()=>{
        this.newFramePlayer();
      },100)
    },
    handleResize() {
      _this.$refs.videos.style.marginLeft = (document.body.clientWidth-window.outerWidth)*0.71+'px'
    },
    gotab(k){
      _this.advantageTab=k
    },
    setTimer:() =>{
      _this.timer = setInterval(() => {
        _this.advantageTab ++
      },5000)
    },
    mouseover:()=>{
      clearInterval(_this.timer)
    },
    mouseleave:() => {
      _this.setTimer()
    },
    mouseOver(ind){
      _this.active=ind
    },
    mouseOut(){
      _this.active=0
    },
    gotoExperience(){
      this.$router.push('/h5/develop/Experience')
    },
    goCaseDetail(id) {
      _this.$router.push({
        name: 'DevelopExperienceDetail',
        query:{
          id: id,
        }
      })
      console.log(id)
    },
    linkTo(val){
        console.log(val,'11111')
        _this.imgIndex = val
    }, 
    chooseCard:function(index){
			_this.$refs.carousel.setActiveItem(index);
		},

    aroundPlay(val) {
      // this.newFramePlayer();
      if(this.framePlayer){
        if(val){
          this.framePlayer.play();
        }else {
          this.framePlayer.stop();
        }
      }
    },
    clickItem(id) {
      this.sanliuleng = id
      let _this = this
      setTimeout(()=>{
        _this.newFramePlayer();
      },100)
    },
    newFramePlayer() {
      if(this.sanliuleng != 4) return
      this.clearFramePlayer();
      this.$nextTick(()=>{
        this.framePlayer = new FramePlayer({
          canvas: ".aroundImgPreview4",
          width: this.canvasWidth,
          height: this.canvasHeight,
          images: this.imgArr,
          fps: 5,
          loop: -1,
          transparent: false,
          autoplay: false
        });
      console.log(this.framePlayer)
        this.framePlayer.on("ready",()=>{
          // this.around_play = true;
          // this.framePlayer.play(0);
          this.framePlayer.play(0);
          console.log(this.framePlayer)
        });
        this.framePlayer.on("update",(options)=>{
          this.frame = options.frame;
        });
      })
    },
    clearFramePlayer() {
      this.around_play = false;
      if(this.framePlayer){
        this.framePlayer.off("ready");
        this.framePlayer.off("update");
        this.framePlayer.stop();
        this.framePlayer = null;
      }
    },
    handleCanvasDown(e) {
      this.canvasDown = true;
      this.coordinate.x = e.clientX;
      this.coordinate.y = e.clientY;
    },
    handleCanvasMove(e) {
      if(this.canvasDown){
        if(e.clientX - this.coordinate.x > 5){
          if(this.frame === this.imgArr.length - 1){
            this.frame = 0;
          }else {
            this.frame++;
          }
          this.coordinate.x = e.clientX;
        }else if(this.coordinate.x - e.clientX > 5){
          if(this.frame === 0){
            this.frame = this.imgArr.length - 1;
          }else {
            this.frame--;
          }
          this.coordinate.x = e.clientX;
        }
        this.coordinate.y = e.clientY;
      }
    },
    handleCanvasUp (e){
      console.log(e)
      this.canvasDown = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.lavBanner {
  ::v-deep .v-banner__wrapper,
  ::v-deep .v-banner__content,
  ::v-deep .v-banner__text {
    padding: 0 !important;
    height: 100%;
    background: #2e2f33;
  }
}

.banner-box {
  margin-top: 15px;
  margin-left: 5px;
  .banner-title {
    margin-bottom: -25px;
    text-shadow: 0px 0px 10px rgb(5 0 45 / 50%) !important;
    position: relative;
    z-index: 2;
  }
  .banner-btns {
    position: relative;
    z-index: 2;
    
  }
  ::v-deep .v-btn__content {
    text-shadow: 0px 0px 10px rgba(5, 0, 45, 0.5) !important;
  }
  &::after {
    content: "";
    background: url('https://h5.ophyer.cn/official_website/other/banner-bgs2.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 60%;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: -95px;
    z-index: 1;
  }
}

::v-deep .swiper-pagination{
  --swiper-pagination-color:white;
}
::v-deep .swiper-pagination-bullet{
  background: white !important;
  margin-right: 10px;
}
::v-deep .theme--light.v-btn{
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400 !important;
  color: #FFFFFF !important;
  border: 1px solid white !important;
  margin-left: 17px !important;
}
.metaverse-title {
    margin-top: 40px;
    margin-bottom: 25px;
    color: #FFFFFF;
    text-align: center;
    position: relative;
    z-index: 6;
    p {
      font-weight: 500;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      color: #FFFFFF;
      line-height: 45px;
      text-shadow: 0px 0px 6px rgba(7, 0, 67, 0.3);
    }
}
.warps {
  margin: 0 auto;        
}

::v-deep .warp1 {
  width: 100%;
 .theme--light.v-image{
    max-width: 1120px;
    margin: 0 auto;
  }
}
.bgimg{
    width: 100%;
    background: url('https://h5.ophyer.cn/official_website/other/develop1-2.png');
    background-position: center center;
}
::v-deep .v-sheet.v-card{
  border-radius: unset;
}
.warpcrate{
   width: 100%;
   background-color: #191B1D;
  .warpcrate-title {
    padding: 16px 0 !important;
    color: #FFFFFF;
    text-align: center;
    position: relative;
    z-index: 6;
    background: rgba(76, 82, 93, 0.15);
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 28px;
    text-shadow: 0px 0px 6px rgba(7, 0, 67, 0.3);
  }
}
::v-deep .warp2{
   width: 100%;
   background: linear-gradient(180deg, rgba(105, 116, 125, 0.2) 0%, rgba(49, 35, 216, 0.1) 100%);
   background-color: #191B1D;
   .theme--light.v-card{
     background: linear-gradient(180deg, rgba(105, 116, 125, 0.2) 0%, rgba(49, 35, 216, 0.1) 100%) !important;
     background-color: #191B1D;
   }
   .theme--light.v-tabs > .v-tabs-bar,.theme--light.v-tabs-items{
     background-color: unset !important;
   }
   .v-tabs-bar__content{
     justify-content: start !important;
   }
   .v-tabs-slider{
     background-color:unset
   }
   .xs-tab{
     margin-top: 0;
   }
  .tab-btn-prev,.tab-btn-next{
    top:33% !important;
  }
  .VRcard{
    background: rgba(0,0,0,.25);
    backdrop-filter: saturate(180%) blur(6px);
    -webkit-backdrop-filter: saturate(180%) blur(6px);
    border-radius: 5px;
    border: 1px solid #7b828d;
  }
   .lineclass{
     width: 7px;
     height: 7px;
     background: rgba(255, 255, 255, 0.3);
     border-radius: 5px;
   }
   .lineclass--active{
     width: 7px;
     height: 7px;
     background: #FFFFFF;
     border-radius: 5px;
   }
   .row{
     margin: 0 auto;
   }
   .v-tab::after{
     background-color: rgba(255, 255, 255, 0.7) !important;
   }
   .v-tab{
     color: rgba(255, 255, 255, 0.7) !important;
     line-height: 21px !important;
     font-size: 15px;
     font-family: PingFangSC-Light, PingFang SC;
     font-weight: 300;
   }
   .v-tab--active{
     font-size: 15px;
     font-family: PingFangSC-Medium, PingFang SC;
     font-weight: 500;
     color: #FFFFFF !important;
     line-height: 21px !important;
   }
  .Home-title {
    margin-top: 4px;
    color: #FFFFFF;
    text-align: center;
    position: relative;
    z-index: 6;
    p:nth-child(1) {
      font-weight: 500;
      font-size: 18px;
    }
    p:nth-child(2) {
      margin: 0 auto;
      max-width: 600px;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      margin-bottom: 0;
    }
  }
  .box {
    width: 1280px;
    margin: 50px auto 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left {
    margin-right: 55px;
    img {
      width: 728px;
      height: 420px;
      border-radius: 30px;
      object-fit: contain
    }
  }
  .right {
    width: 544px;
    padding-left: 40px;
    padding-top: 66px;
    height: 295px;
    background: linear-gradient(180deg, rgba(105, 116, 125, 0.2) 0%, rgba(49, 35, 216, 0.1) 100%);
    border-radius: 5px;
    margin-left: -150px;
    .v-card__text {
      padding: 0;
    }
    .title {
      font-size: 24px !important;
      font-family: PingFangSC-Medium, PingFang SC !important;
      font-weight: 500 !important;
      color: #FFFFFF !important;
      line-height: 33px !important;
    }
    .sub{
      font-size: 16px !important;
      font-family: PingFangSC-Regular, PingFang SC !important;
      font-weight: 400 !important;
      line-height: 22px !important;
      color: #FFFFFF !important;
      margin-top: 10px;
    }
    .tip {
      margin: 20px 0 !important;
      font-size: 14px !important;
      font-family: PingFangSC-Light, PingFang SC !important;
      font-weight: 300 !important;
      color: #FFFFFF !important;
      line-height: 26px !important;
    }
  }
  .lineborder{
    width: 100%;
    height: 0.5px;
    background: #4C525D;
    border-radius: 1px;
    margin: 30px auto;
  }
}
.carousel-container {
    .gitStyle{
      position: relative;
      .itemGif{
        position: absolute;
        top: 20px;
        left: 149px;
        z-index: 100;
        width: 101px;
        height: 65px;
        .imgone{
          border-radius:5px;
          width:170px;
          // height:155px
        }
        .imgTwo{
          //width: 167px;
          position: absolute;
          top: 14px;
          left: 34px;
          width:100%;
          height: 100%;
        }
      }
    }
    .coverleft{
      width: 176px;
      height: 100%;
      background: linear-gradient(180deg, rgba(41, 45, 49, 0.8) 0%, rgba(19, 15, 70, 0.7) 100%);
      border-radius: 5px 0px 0px 5px;
      padding-left:13px;
      padding-right: 10px;
      float: left;
      position: relative;
      h2{
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 22px;
        padding-top: 10px;
      }
      h3{
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 20px;
        padding-top: -20px;
      }
      p{
        font-size: 10px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: rgba(255, 255, 255, 0.7);
        line-height: 14px;
        padding-top: 11px;
      }
      .text-ends {
        position: absolute;
        top: 0;
        left: 0;
        //width: 100%;
        width: 176px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding:0px 10px;
      }
    }
 .swiper-wrapper {
  .swiper-slide {
    //width: 85% !important;
    width: 90% !important;
    margin: 0 auto;
    //height: 280px !important;
    img {
      text-align: center;
      margin: 0 auto;
      width: 100%;
    }
  }
  .swiper-slide-prev{
    transform: scale(0.9);
  }
  .swiper-slide-next{
    transform: scale(0.9);
  }
}
}
::v-deep .warp3{
   width: 100%;
   background: linear-gradient(180deg, rgba(105, 116, 125, 0.2) 0%, rgba(49, 35, 216, 0.1) 100%);
   background-color: #191B1D;
   .theme--light.v-card{
     background-color:unset;
   }
   .metaverse-title{
     margin-top: 30px;
     margin-bottom: 22px !important;
   }
   .lineborder{
    width: 100%;
    height: 1px;
    background: #4C525D;
    border-radius: 1px;
    margin: 0 auto;
    //margin-top: 40px;
  }
  .coverleft{
      width: 176px;
      height: 280px;
      background: linear-gradient(180deg, rgba(41, 45, 49, 0.8) 0%, rgba(19, 15, 70, 0.7) 100%);
      border-radius: 5px 0px 0px 5px;
      padding-left:13px;
      padding-right: 10px;
      float: left;
      position: relative;
      h2{
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 22px;
        padding-top: 10px;
      }
      h3{
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 20px;
        padding-top: 80px;
      }
      p{
        font-size: 10px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: rgba(255, 255, 255, 0.7);
        line-height: 14px;
        padding-top: 11px;
      }
  }
  .swiper-wrapper .swiper-slide{
    background: #A5A5B3;
    border-radius: 5px;
    width: 340px !important;
    height: 280px !important;
     .v-image__image{
       width: 167px;
       margin-left: 136px;
       margin-top: -35px;
       object-fit: contain;
       z-index: 99;
     }
  }
}
.warp4{
  width: 100%;
  .metaverse-title{
    margin-top: 30px !important;
    margin-bottom: 0px !important;
  }
}
.item-card{
        background: #fff;
        box-shadow: 0px 0px 18px 0px rgba(0, 92, 193, 0.16);
        border-radius: 5px !important;

        .text-box {
          .title {
            font-size: 14px !important;
            font-weight: 400;
            color: #333333;
            padding: 0 !important;
            margin-left: 3px;
            margin: 0px 0 8px 0px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: start;
            height: unset;
            line-height: 20px;
          }
          .tip{
            font-size: 12px;
            color: #999999;
            line-height: 17px;
          }
          &::after {
            content: "";
            background-image: url("../../../../assets/images/home/case-icon.png");
            background-size: 100%;
            width: 20px;
            height: 20px;
            position: absolute;
            bottom: 5px;
            left: 10px;
            padding-right: 5px;
          }
          &:focus {
            display: none !important;
          }
        }
}
.sizeStyle{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.more{
  cursor: pointer;
}

.dialog {
  position: fixed;
  z-index:1001;
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,.7);
  .content-tryout{
    width: 80%;
    max-width: 380px;
    height: 270px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #FFFFFF;
    box-shadow: 0px 0px 15px 5px rgb(0 114 255 / 10%);
    
    .close-btn{
      position: absolute;
      top: 0;
      right: 0;
      color: rgba(0, 0, 0, 0.6) !important;
      cursor: pointer;
    }
    .box {
      margin-top: 15px;
      display: flex;
      align-items: center;
      margin-left: 35px;
      img {
        width: 48px;
        height: 48px;
        margin-right: 5px;
      }
      p {
        margin-bottom: 0;
      }
      p:nth-child(1) {
        font-size: 20px;
        font-weight: 500;
        color: #333333;
      }
      p:nth-child(2) {
        margin-top: 5px !important;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }
    }
  }
}

#aroundImgPreview, .aroundImgPreview4 {
  border-radius: 30px;
  position: absolute;
  z-index: 1;
  left: 20px;
}
</style>
